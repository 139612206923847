import {
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  Modal,
  Progress,
  Select,
  Stack,
  Table,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useDisclosure } from "@mantine/hooks";
import { format } from "date-fns";

import "./transition.css";
import { TRecommendation, TRecommendationDetail } from "../../../types";
import { clientService } from "../../../api";

type Props = {
  image: string;
  title: string;
  completedRecommendations: number;
  totalRecommendations: number;
  recommendations: TRecommendation[];
  onSelectChange: () => Promise<void>;
  children: string;
  code: string;
  defaultOpened?: boolean;
};

const useStyles = createStyles(() => ({
  arrow: {
    cursor: "pointer",
  },
  showOnSmall: {
    display: "none",
    "@media (max-width: 1024px)": {
      display: "block",
    },
  },
  hideOnSmall: {
    "@media (max-width: 1024px)": {
      display: "none",
    },
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "justify",
    maxWidth: "100%",
    "@media (max-width: 1024px)": {
      whiteSpace: "normal",
      overflow: "visible",
      textOverflow: "clip",
    },
  },
  progressBar: {
    width: 250,
    maxWidth: "300px",
    "@media (max-width: 1024px)": {
      maxWidth: "300px", // Ajustar el ancho del progress bar en móviles
      marginBottom: "20px", // Añadir margen inferior en móviles
    },
  },
  NoProgressBar: {
    width: 250,
    maxWidth: 600,
    "@media (max-width: 1024px)": {
      marginBottom: "20px", // Añadir margen inferior en móviles
    },
  },
  mobileStack: {
    "@media (max-width: 1024px)": {
      flexDirection: "column", // Apilar elementos en móviles
      alignItems: "center", // Centrar los elementos apilados
    },
  },
}));

export const PlanDeAccionCard = ({
  image,
  title,
  completedRecommendations,
  totalRecommendations,
  recommendations,
  onSelectChange,
  children,
  code,
  defaultOpened = false,
}: Props) => {
  const [tableShown, setTableShown] = useState(defaultOpened);
  const { classes } = useStyles();
  const percentage = Math.round(
    (completedRecommendations / totalRecommendations) * 100
  );
  const [opened, { open, close }] = useDisclosure(false);
  const [recommendationDetail, setRecommendationDetail] =
    useState<TRecommendationDetail | null>(null);
  const [loading, setLoading] = useState(false);

  const verDetalleButton = (id: number) => (
    <Flex justify="center">
      <Button
        onClick={() => {
          open();
          getRecommendationDetail(id);
        }}
        disabled={loading}
        color="blue"
        variant="light"
        fz="xs"
      >
        🔎
      </Button>
    </Flex>
  );

  const getRecommendationDetail = async (id: number) => {
    setLoading(true);
    const response = await clientService.actionPlan.recommendation.get(
      code,
      id
    );
    setRecommendationDetail(response.data);
    setLoading(false);
  };

  const onStatusChange = async (id: number, status: string | null) => {
    setLoading(true);
    await clientService.actionPlan.recommendation.put(code, id, {
      status: status,
      ended_at:
        status === "Completado" ? format(new Date(), "yyyy-MM-dd") : null,
    });
    await onSelectChange();
    setLoading(false);
  };

  const tabla = () => (
    <Card p={0} w="100%" style={{ overflow: "visible" }}>
      <Table withBorder striped withColumnBorders>
        <thead>
          <tr>
            <th>Nombre</th>
            <th className={classes.hideOnSmall}>Problema</th>
            <th className={classes.hideOnSmall}>Solución</th>
            <th scope="col" style={{ width: "5%" }}>
              Estado
            </th>
            <th scope="col" style={{ width: "5%" }}>
              Recursos y detalle
            </th>
          </tr>
        </thead>
        <tbody>
          {recommendations.map((item, index) => (
            <tr key={index}>
              <td>
                <Text>{item.action.name}</Text>
              </td>
              <td className={classes.hideOnSmall}>
                <Text ta="justify">{item.action.problem}</Text>
              </td>
              <td className={classes.hideOnSmall}>
                <Text ta="justify">{item.action.solution}</Text>
              </td>
              <td>
                <Select
                  disabled={loading}
                  data={[
                    { value: "Completado", label: "🟢 Completado" },
                    { value: "En ejecución", label: "🟡 En ejecución" },
                    { value: "Pendiente", label: "🌑 Pendiente" },
                  ]}
                  value={item.status.toString()}
                  sx={{
                    width: 150,
                    "@media (max-width: 1024px)": {
                      width: 120,
                      textOverflow: "ellipsis",
                    },
                  }}
                  onChange={(value) => onStatusChange(item.id, value)}
                />
              </td>
              <td>{verDetalleButton(item.id)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );

  return (
    <>
      <Card withBorder p={0}>
        <Flex
          align="center"
          justify="space-between"
          gap="sm"
          className={classes.mobileStack}
        >
          <Flex gap="xl">
            <Image
              src={image}
              alt={title}
              width={150}
              className={classes.hideOnSmall}
            />
            <Flex align="center" my="md" px="sm">
              <Stack spacing="sm">
                <Title order={5}>{title}</Title>
                <Image
                  src={image}
                  alt={title}
                  width="100%"
                  height={100}
                  className={classes.showOnSmall}
                />
                <Text fz="sm" className={classes.description}>
                  {children}
                </Text>
              </Stack>
            </Flex>
          </Flex>
          <Flex>
            <Stack spacing="sm">
              {totalRecommendations ? (
                <>
                  <Text fz="xs">
                    {completedRecommendations} de {totalRecommendations}{" "}
                    recomendaciones completadas
                  </Text>
                  <Progress
                    color={percentage === 100 ? "green" : "blue"}
                    value={percentage <= 10 ? 10 : percentage}
                    label={`${percentage}%`}
                    size={15}
                    className={classes.progressBar}
                  />
                </>
              ) : (
                <>
                  <Text fz="xs">No se han asignado recomendaciones</Text>
                  <Progress
                    color="gray"
                    value={100}
                    label={`N/A`}
                    size={15}
                    striped
                    className={classes.NoProgressBar}
                  />
                </>
              )}
            </Stack>
            <Image
              className={`${classes.arrow} arrow-transition ${
                tableShown ? "arrow-rotated" : ""
              }`}
              src={"/img/pda/chevron-derecha.png"}
              alt="Ver recomendaciones"
              width={50}
              onClick={() => setTableShown(!tableShown)}
              style={{
                visibility: totalRecommendations ? "visible" : "hidden",
              }}
              radius="xl"
            />
          </Flex>
        </Flex>
        <CSSTransition
          in={tableShown}
          timeout={tableShown ? 500 : 0}
          classNames="table"
          unmountOnExit
        >
          <div>
            <Divider />
            <Group p="sm">{tabla()}</Group>
          </div>
        </CSSTransition>
      </Card>

      {recommendationDetail && !loading && (
        <Modal
          opened={opened}
          onClose={close}
          title={
            <Title
              sx={{
                fontSize: 24,
                "@media (max-width: 1024px)": {
                  fontSize: 18,
                },
              }}
            >
              {recommendationDetail.action.name}
            </Title>
          }
          size="lg"
          zIndex={9999}
        >
          <Stack spacing="md">
            <Title order={4}>Descripción de la acción</Title>
            <Text ta="justify">{recommendationDetail.action.description}</Text>

            <Title order={4}>Área Responsable</Title>
            <Text ta="justify">{recommendationDetail.action.responsible}</Text>

            <Title order={4}>Indicador ELSA</Title>
            <Text ta="justify">{recommendationDetail.action.metric}</Text>

            <Title order={4}>Valor obtenido en el indicador</Title>
            <Text ta="justify">{recommendationDetail.result}</Text>

            {recommendationDetail.ended_at && (
              <Flex>
                <Badge color="blue">
                  Cumplido el:{" "}
                  {format(recommendationDetail.ended_at, "dd/MM/yyyy")}
                </Badge>
              </Flex>
            )}

            {recommendationDetail.action.linked_resources && (
              <Group position="right">
                <Button
                  color="blue"
                  variant="outline"
                  onClick={() =>
                    window.open(
                      recommendationDetail.action.linked_resources,
                      "_blank"
                    )
                  }
                >
                  Descargar recursos
                </Button>
              </Group>
            )}
          </Stack>
        </Modal>
      )}
    </>
  );
};
